import React from 'react'
import { useRecordContext, useTranslate } from 'react-admin'
import { Chip } from '@mui/material'

import { Contact, ContactSubjectEnum } from '../types'

const ContactSubjectField = (props: any) => {
  const translate = useTranslate()
  const record = useRecordContext<Contact>(props)
  switch (record.subject) {
    case ContactSubjectEnum.LOVE:
      return <Chip label={translate(`resources.contact.subject.${record.subject}`)} />
    case ContactSubjectEnum.REWARD:
      return <Chip color="warning" label={translate(`resources.contact.subject.${record.subject}`)} />
    case ContactSubjectEnum.BUG:
      return <Chip color="error" label={translate(`resources.contact.subject.${record.subject}`)} />
    default:
      return <Chip label={translate(`resources.contact.subject.${record.subject}`)} />
  }
}

export { ContactSubjectField }
