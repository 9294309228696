import { HttpError } from 'react-admin'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { isBefore } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import Api from '../api'

interface CheckRequest {
  statusCode: number
  message: string
  error: string
}

interface AccountPayload extends JwtPayload {
  id: string
  fullName: string
  avatar: string
}

const authProvider = {
  login: async ({ username, password }: { username: string; password: string }): Promise<any> => {
    return await Api.login(username, password)
  },
  logout: async (params: any): Promise<void | false | string> => {
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken) {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('permissions')
      Api.logout(refreshToken)
    }
    return
  },
  getIdentity: () => {
    const token = localStorage.getItem('token')
    if (token) {
      const { id, fullName, avatar } = jwtDecode<AccountPayload>(token)
      return Promise.resolve({ id, fullName, avatar })
    }
    return Promise.reject()
  },
  checkAuth: async (params: CheckRequest): Promise<void> => {
    const token = localStorage.getItem('token')
    if (token) {
      return Promise.resolve()
    } else {
      return Promise.reject({ redirectTo: '/login' })
    }
  },
  checkError: async ({ status }: { status: number }): Promise<void> => {
    if (/*status === 401 ||*/ status === 403) {
      return Promise.reject()
    }
    return Promise.resolve()
  },
  getPermissions: async (params: any): Promise<any> => {
    const token = localStorage.getItem('token')
    let isExpired = false
    if (token) {
      const decoded = jwtDecode<JwtPayload>(token)
      if (decoded.exp) {
        const exp = utcToZonedTime(decoded.exp * 1000, 'Europe/Paris')
        const before = isBefore(new Date(), exp)
        if (!before) {
          isExpired = true
        }
      } else {
        isExpired = true
      }
    }
    const p = localStorage.getItem('permissions')
    let permissions: any
    if (p && !isExpired) {
      permissions = JSON.parse(p)
    } else {
      permissions = await Api.get('resources').json()
      if (permissions.statusCode === 401) {
        throw new HttpError(permissions.message, permissions.statusCode)
      }
      localStorage.setItem('permissions', JSON.stringify(permissions))
    }
    return {
      permissions,
      roles: ['reader']
    }
  }
}

export default authProvider
