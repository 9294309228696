import { useTranslate } from 'react-admin'
import { CreateParams, DataProvider, fetchUtils } from 'ra-core'
import { CondOperator, QueryFilter, QuerySort, RequestQueryBuilder } from '@nestjsx/crud-request'
import { stringify } from 'querystring'

import Api from './api'
import { Role } from '../types'
import useProfile from './useProfile'

const composeFilter = (paramsFilter: any): QueryFilter[] => {
  const flatFilter = fetchUtils.flattenObject(paramsFilter)
  return Object.keys(flatFilter).map((key) => {
    const splitKey = key.split('||')

    let field = splitKey[0]
    let ops = splitKey[1]
    if (!ops) {
      if (
        typeof flatFilter[key] === 'boolean' ||
        typeof flatFilter[key] === 'number' ||
        (typeof flatFilter[key] === 'string' && flatFilter[key].match(/^\d+$/))
      ) {
        ops = CondOperator.EQUALS
      } else {
        ops = CondOperator.CONTAINS
      }
    }

    if (field.startsWith('_') && field.includes('.')) {
      field = field.split(/\.(.+)/)[1]
    }
    return { field, operator: ops, value: flatFilter[key] } as QueryFilter
  })
}

const composeQueryParams = (queryParams: any = {}): string => stringify(fetchUtils.flattenObject(queryParams))

const mergeEncodedQueries = (...encodedQueries: string[]): string =>
  encodedQueries
    .filter((e) => e)
    .map((query: string) => query)
    .join('&')

const DataProviderImpl = (apiUrl: string): DataProvider => {
  const profile = useProfile()
  const translate = useTranslate()

  const httpClient = (apiUrl: string, options: any = {}) => {
    options = {
      headers: new Headers({ Accept: 'application/json' })
    }
    const token = localStorage.getItem('token')
    if (token) {
      options.headers.set('Authorization', `Bearer ${token}`)
    }
    return fetchUtils.fetchJson(apiUrl, options)
  }

  return {
    getList: async (resource, params) => {
      let { page, perPage } = params.pagination
      page = page ?? 1
      const { q: queryParams, ...filter } = params.filter || {}
      const qb = RequestQueryBuilder.create().setLimit(perPage).setPage(page)
      let url = ''
      if (resource === 'challenge_session') {
        resource = 'challenge'
        const query: string = qb
          .setFilter({
            field: 'name',
            operator: CondOperator.CONTAINS,
            value: queryParams
          })
          .sortBy(params.sort as QuerySort)
          .setOffset((page - 1) * perPage)
          .query()
        url = `${apiUrl}/${resource}?${query}`
      } else if (resource === 'account_admin_or_partner') {
        resource = 'account'
        if (queryParams) {
          qb.setFilter({
            field: 's',
            operator: CondOperator.CONTAINS,
            value: queryParams
          })
        }
        qb.setFilter({
          field: 'role',
          operator: CondOperator.IN,
          value: [Role.Advertiser, Role.Admin]
        })
        qb.sortBy(params.sort as QuerySort)
        const query = qb.query()
        url = `${apiUrl}/${resource}?${query}`
      } else {
        const encodedQueryParams = composeQueryParams(queryParams)
        const encodedQueryFilter = RequestQueryBuilder.create({
          filter: composeFilter(filter)
        })
          .setLimit(perPage)
          .setPage(page)
          .sortBy(params.sort as QuerySort)
          .setOffset((page - 1) * perPage)
          .query()

        const query: string = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter)
        url = `${apiUrl}/${resource}?${query}`
      }

      const { json } = await httpClient(url)
      // redefined in order to use pagination
      return {
        data: json.items,
        total: json.meta.totalItems
      }
    },

    getOne: (resource, params) => {
      return Api.get(`${resource}/${params.id}`)
        .json()
        .then((data: any) => ({
          data
        }))
    },

    getMany: async (resource, params) => {
      const queryBuilder = RequestQueryBuilder.create()
      queryBuilder.setPage(1)
      queryBuilder.setLimit(20)
      /// Wut? I'm not sure if it's the right way to proceed...
      if (resource === 'account_admin_or_partner') {
        queryBuilder.setFilter({
          field: 'role',
          operator: CondOperator.IN,
          value: [Role.Advertiser, Role.Admin]
        })
        const json: any = await Api.get(`account?${queryBuilder.query()}`).json()
        return { data: json.items }
      } else {
        queryBuilder.setFilter({
          field: 'id',
          operator: CondOperator.IN,
          value: `${params.ids}`
        })
        const json: any = await Api.get(`${resource}?${queryBuilder.query()}`).json()
        return { data: json.items }
      }
    },

    getManyReference: (resource, params) => {
      let { page, perPage } = params.pagination
      page = page ?? 1
      const { q: queryParams, ...otherFilters } = params.filter || {}
      const filter: QueryFilter[] = composeFilter(otherFilters)

      filter.push({
        field: params.target,
        operator: CondOperator.EQUALS,
        value: params.id
      })
      const encodedQueryParams = composeQueryParams(queryParams)
      const encodedQueryFilter = RequestQueryBuilder.create({
        filter
      })
        .sortBy(params.sort as QuerySort)
        .setPage(page)
        .setLimit(perPage)
        .setOffset((page - 1) * perPage)
        .query()
      const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter)
      console.log(query)
      const url = `${apiUrl}/${resource}?${query}`

      // redefined in order to use pagination
      return httpClient(url).then(({ json }) => ({
        data: json.items,
        total: json.meta.totalItems
      }))
    },

    update: async (resource, params) => {
      let data: any
      if (resource === 'challenge') {
        const formData = new FormData()
        for (const [key, value] of Object.entries<any>(params.data)) {
          /// XXX: no other solution?
          if (key === 'images' || key === 'prizes') {
            if (key === 'images' && value === undefined) {
              return Promise.reject({ message: 'challenge_create_images_required' })
            }
            if (key === 'prizes' && value === undefined) {
              return Promise.reject({ message: 'challenge_create_prizes_required' })
            }
            for (const img of value) {
              if (img?.id) {
                //console.log(`existing ${key}, doing nothing`, img)
              } else {
                formData.append(key, img.rawFile, img.title)
              }
            }
          } else {
            formData.append(key, value)
          }
        }
        data = await Api.patchFormData(`${resource}/${params.id}`, formData).json()
      } else {
        /// XXX
        if (resource === 'account') {
          data = await Api.patch(`${resource}/${params.id}/admin`, params.data).json()
        } else {
          data = await Api.patch(`${resource}/${params.id}`, params.data).json()
        }
      }
      if (data.statusCode >= 400) {
        return Promise.reject({ message: data.message })
      }
      return { data }
    },

    updateMany: (resource, params) =>
      Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data)
          })
        )
      ).then((responses) => ({
        data: responses.map(({ json }) => json)
      })),

    create: async (resource: string, params: CreateParams) => {
      let data: any
      if (resource === 'challenge') {
        const formData = new FormData()
        for (const [key, value] of Object.entries<any>(params.data)) {
          /// XXX: no other solution?
          if (key === 'images') {
            if (key === 'images' && value === undefined) {
              return Promise.reject({ message: 'challenge_create_images_required' })
            }
            for (const img of value) {
              formData.append(key, img.rawFile, img.title)
            }
          } else if (key === 'prizes') {
            /*if (key === 'prizes' && value === undefined) {
              return Promise.reject({ message: 'challenge_create_images_required' })
            }*/
            for (const img of value) {
              formData.append(key, img.rawFile, img.title)
            }
          } else if (key === 'teams') {
            for (const team of value) {
              formData.append('teams[]', JSON.stringify(team))
            }
          } else {
            formData.append(key, value)
          }
        }
        if (params.data.onlyAdminCanCreateTeams === undefined) {
          formData.append('onlyAdminCanCreateTeams', '0')
        }
        if (profile.isAdmin()) {
          data = await Api.postFormData(resource, formData).json()
        } else {
          data = await Api.postFormData(`${resource}/partner`, formData).json()
        }
      } else {
        data = await Api.post(resource, params.data).json()
      }
      if (data.statusCode >= 400) {
        return Promise.reject({ message: data.message })
      }
      return { data }
    },

    delete: (resource, params) => {
      return Api.delete(`${resource}/${params.id}`).then((json: any) => {
        if (json.statusCode >= 400) {
          return Promise.reject({ message: json.message })
        }
        return {
          data: { ...json, id: params.id }
        }
      })
    },

    deleteMany: async (resource, params) => {
      return Promise.all(
        params.ids.map(async (id) => {
          const data: any = await Api.delete(`${resource}/${id}`).json()
          if (data.statusCode >= 400) {
            return Promise.reject({
              message: translate(`resources.errors.${data.message}`)
            })
          }
          return {
            data
          }
        })
      ).then((responses) => {
        return { data: responses.map(({ json }: any) => json) }
      })
    }
  }
}

export default DataProviderImpl
