import { RaRecord, usePermissions } from 'react-admin'
import { CrudActions } from '../types'

interface CanAccessProps {
  action: CrudActions
  resource: string
  record?: RaRecord
}

export const useCanAccess = (props: CanAccessProps): { isLoading: boolean; canAccess: boolean } => {
  const { isLoading, permissions } = usePermissions()
  if (isLoading) {
    return { isLoading, canAccess: false }
  } else if (props.record) {
    return { isLoading, canAccess: false }
  } else {
    const index = permissions.permissions.map((p: any) => p.resource).findIndex((resource: string) => resource === props.resource)
    if (index === -1) {
      return { isLoading, canAccess: false }
    } else {
      const permission = permissions.permissions[index]
      return { isLoading, canAccess: permission.action.includes(props.action) }
    }
  }
}
