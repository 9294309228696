import jwtDecode, { JwtPayload } from 'jwt-decode'
import ky from 'ky'

export interface AccountPayload extends JwtPayload {
  role: string
  emailValid: boolean
}

const k = ky.create({
  prefixUrl: process.env.REACT_APP_API_ENDPOINT,
  timeout: 90000,
  throwHttpErrors: false,
  hooks: {
    beforeRequest: [
      (request) => {
        const token = localStorage.getItem('token')
        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`)
        } else {
          console.log('ApiKy > No bearer found')
        }
      }
    ]
  }
})

const k2 = k.extend({
  hooks: {
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401) {
          console.log('ApiKy > afterResponse with 401')
          const refreshToken = localStorage.getItem('refreshToken')
          if (refreshToken) {
            const res = await k.post('auth/refresh', { json: { refreshToken, origin: request.url } }).json<LoginResponse>()
            if (res.token) {
              localStorage.setItem('token', res.token)
              localStorage.setItem('refreshToken', res.refreshToken)
              request.headers.set('Authorization', `Bearer ${res.token}`)
              console.log('ApiKy > restarting request?')
              return k(request)
            } else {
              console.log('ApiKy > No token, so what?')
              return response
            }
          } else {
            console.log('ApiKy > No refreshToken token, so what?')
            return response
          }
        }
      }
    ]
  }
})

class LoginResponse {
  token!: string
  refreshToken!: string
}

class ApiKy {
  private api: typeof ky
  constructor() {
    this.api = k2
  }

  public get = (path: string, options?: object) => this.api.get(path, options)
  public patch = (path: string, json: object) => this.api.patch(path, { json })
  public patchFormData = (path: string, body: FormData) => this.api.patch(path, { body })
  public post = (path: string, json: object) => this.api.post(path, { json })
  public postFormData = (path: string, body: FormData) => this.api.post(path, { body })

  public delete = (path: string) => this.api.delete(path)

  async login(username: string, password: string) {
    const res = await this.post('auth/login', { username, password }).json<LoginResponse>()
    this.saveTokens(res)
    return res
  }

  async saveTokens(res: any): Promise<boolean> {
    if (res.token) {
      const decoded = jwtDecode<AccountPayload>(res.token)
      if (decoded.emailValid) {
        localStorage.setItem('token', res.token)
        localStorage.setItem('refreshToken', res.refreshToken)
        return true
      }
    }
    return false
  }

  async logout(refreshToken: string): Promise<boolean> {
    return this.post('auth/logout', { refreshToken }).json<boolean>()
  }
}

const Api = new ApiKy()

export default Api
