import React from 'react'
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  DateInput,
  DateTimeInput,
  FileInput,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  Labeled,
  minValue,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceInput,
  regex,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useCreate,
  useNotify,
  useRedirect,
  useTranslate
} from 'react-admin'
import { add, format } from 'date-fns'
import { Grid, Typography } from '@mui/material'
import jwtDecode from 'jwt-decode'

import { ChallengeCriteriaInput } from '../components/ChallengeFields'
import { Role } from '../types'
import { AccountPayload } from '../core/api'

const __DEV__ = process.env.NODE_ENV === 'development'

const dv = {
  name: __DEV__ ? 'Nom du challenge' : '',
  description: __DEV__ ? 'Description du challenge' : '',
  url: __DEV__ ? 'https://www.queeny.fr' : '',
  startDate: format(new Date(), 'yyyy-MM-dd'),
  endDate: format(add(new Date(), { months: 1 }), 'yyyy-MM-dd'),
  scope: __DEV__ ? 'private' : '',
  code: __DEV__ ? Math.floor(Math.random() * 10000000000) : ''
}

const ChallengeCreate = () => {
  const translate = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const [create] = useCreate()

  const onSubmit = async (data: any) => {
    try {
      await create('challenge', { data }, { returnPromise: true })
      notify('Votre Challenge a été créé avec succès !', { type: 'success' })
      redirect('list', 'challenge')
    } catch (error: any) {
      notify(translate(`resources.errors.${error.message}`), { type: 'warning' })
    }
  }

  const renderSelectPartner = () => {
    const token = localStorage.getItem('token') || '{}'
    const decoded = jwtDecode<AccountPayload>(token)
    if (decoded.role === Role.Admin) {
      return (
        <Grid item sm={12} md={6}>
          <ReferenceInput source="account" reference="account_admin_or_partner" enableGetChoices={() => true}>
            <AutocompleteInput
              validate={required()}
              optionText={(record) => {
                const suffix = record.role === Role.Admin ? ' (admin)' : ' (annonceur)'
                return record.email + suffix
              }}
              label="resources.challenge.labels.partner"
            />
          </ReferenceInput>
        </Grid>
      )
    } else {
      return false
    }
  }

  return (
    <Create>
      <TabbedForm onSubmit={onSubmit} warnWhenUnsavedChanges>
        <FormTab label={translate('resources.challenge.tabs.intro')}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <TextInput fullWidth source="name" label="resources.challenge.fields.name" defaultValue={dv.name} validate={required()} />
            </Grid>
            <Grid item sm={12}>
              <TextInput
                fullWidth
                source="description"
                label="resources.challenge.fields.description"
                multiline
                defaultValue={dv.description}
                validate={required()}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextInput
                fullWidth
                source="url"
                label="resources.challenge.labels.url"
                type="url"
                helperText="resources.challenge.helpers.url"
                defaultValue={dv.url}
                validate={[required(), regex(/^https/, "L'URL n'est pas au bon format")]}
              />
            </Grid>
            {renderSelectPartner()}
            <Grid item sm={12} md={3}>
              <DateInput
                fullWidth
                source="startDate"
                label="resources.challenge.fields.startDate"
                defaultValue={dv.startDate}
                validate={required()}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <DateInput
                fullWidth
                source="endDate"
                label="resources.challenge.fields.endDate"
                defaultValue={dv.endDate}
                validate={required()}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <NumberInput
                fullWidth
                source="priority"
                label="resources.challenge.fields.priority"
                min="0"
                max="100"
                defaultValue="50"
                step="5"
                validate={required()}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <NumberInput
                fullWidth
                source="sessionDuration"
                label="resources.challenge.fields.sessionDuration"
                min="10"
                max="60"
                step="5"
                parse={(v: string) => parseInt(v) * 60}
                format={(v: number) => v / 60}
                validate={required()}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <SelectInput
                fullWidth
                validate={required()}
                source="scope"
                label="resources.challenge.fields.scope"
                defaultValue={dv.scope}
                choices={[
                  {
                    id: 'public',
                    name: 'resources.challenge.scope.public'
                  },
                  {
                    id: 'private',
                    name: 'resources.challenge.scope.private'
                  }
                ]}
              />
            </Grid>
            <Grid item sm={12} md={3}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.scope === 'private' && (
                    <TextInput fullWidth source="code" defaultValue={dv.code} {...rest} validate={required()} />
                  )
                }
              </FormDataConsumer>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label={translate('resources.challenge.tabs.images')}>
          <Typography variant="caption">Ajouter dans cette section une ou plusieurs images pour votre Challenge</Typography>
          <Typography variant="caption">
            Il est obligatoire de mettre au moins une bannière. Si vous mettez plusieures images, un carrousel s'affichera dans l'App
          </Typography>
          <Typography variant="caption" marginBottom={2}>
            Chaque bannière ne doit pas dépasser 3 Mo. Un format rectangulaire 2:1 de 1000px par 500px est recommandé.
          </Typography>
          <ImageInput source="images" accept="image/*" maxSize={3000000} multiple>
            <ImageField source="src" title="title" />
          </ImageInput>
        </FormTab>
        <FormTab label={translate('resources.challenge.fields.objective')}>
          <Grid container spacing={3}>
            <Grid item sm={12} md={6}>
              <RadioButtonGroupInput
                margin="none"
                source="type"
                label="resources.challenge.labels.type"
                choices={[
                  { id: 'solo', name: 'resources.challenge.fields.solo' },
                  { id: 'team', name: 'resources.challenge.fields.team' }
                ]}
                defaultValue="solo"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.type === 'team' && (
                    <NumberInput
                      fullWidth
                      source="maxTeamSize"
                      min="2"
                      max="40"
                      label="resources.challenge.fields.maxTeamSize"
                      {...rest}
                      validate={required()}
                    />
                  )
                }
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.type === 'team' && (
                    <BooleanInput
                      source="onlyAdminCanCreateTeams"
                      label="resources.challenge.fields.onlyAdminCanCreateTeams"
                      defaultValue={false}
                    />
                  )
                }
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.onlyAdminCanCreateTeams === true && (
                    <ArrayInput source="teams" label="resources.challenge.fields.teams">
                      <SimpleFormIterator>
                        <TextInput source="name" label="resources.team.fields.name" />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12} md={6}>
              <RadioButtonGroupInput
                margin="none"
                source="criteria"
                label="resources.challenge.labels.criteria"
                choices={[
                  {
                    id: 'distance',
                    name: 'resources.challenge.criterias.distance'
                  },
                  {
                    id: 'calories',
                    name: 'resources.challenge.criterias.calories'
                  },
                  {
                    id: 'duration',
                    name: 'resources.challenge.criterias.duration'
                  }
                ]}
                defaultValue="distance"
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormDataConsumer>
                {({ formData }) => {
                  if (formData.criteria === 'distance') {
                    return <ChallengeCriteriaInput label="resources.challenge.criterias.distance" suffix="Km" />
                  } else if (formData.criteria === 'calories') {
                    return <ChallengeCriteriaInput label="resources.challenge.criterias.calories" suffix="KCal" />
                  } else {
                    return <ChallengeCriteriaInput label="resources.challenge.criterias.duration" suffix="Heures" />
                  }
                }}
              </FormDataConsumer>
            </Grid>
            <Grid item sm={12} md={6}>
              <Labeled label="resources.challenge.labels.global">
                <BooleanInput source="global" defaultValue={false} />
              </Labeled>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label={translate('resources.challenge.tabs.prizes')}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Labeled label="resources.challenge.helpers.generatePrizes">
                <BooleanInput source="generatePrizes" defaultValue={false} />
              </Labeled>
            </Grid>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.generatePrizes ? (
                  <>
                    <Grid item xs={12} sm={6}>
                      <NumberInput
                        fullWidth
                        source="qrCodePoolSize"
                        label="resources.challenge.fields.qrCodePoolSize"
                        min="1"
                        max="200"
                        defaultValue="1"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <NumberInput
                        fullWidth
                        source="qrCodeCardCredit"
                        label="resources.challenge.fields.qrCodeCardCredit"
                        min="1"
                        max="10"
                        defaultValue="1"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">Restreindre les Groupes Machines (par défaut, tous sont actifs)</Typography>
                      <SelectArrayInput
                        fullWidth
                        label="resources.challenge.fields.qrCodeGroups"
                        source="qrCodeGroups"
                        choices={[
                          { id: 6708, name: 'Patismatique' },
                          { id: 6961, name: 'Gratuite' },
                          { id: 7113, name: 'Snack' },
                          { id: 7114, name: 'Boissons chaudes' },
                          { id: 9276, name: 'Autre' }
                        ]}
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="caption">
                        Ajouter dans cette section une ou plusieurs récompenses pour votre Challenge
                      </Typography>
                      <Typography variant="caption">Chaque lot à remporter ne doit pas dépasser 3 Mo</Typography>
                      <Typography variant="caption">
                        Dès qu'un participant (ou une équipe) remporte un Challenge, il reçoit un lot par email ; accessible également dans
                        l'App.
                      </Typography>
                      <Typography variant="caption" marginBottom={2}>
                        La première image correspond au premier lot, la deuxième image au deuxième lot, et ainsi de suite.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Labeled label="resources.challenge.helpers.prizesArePdf">
                        <BooleanInput source="prizesArePdf" label="Utiliser le format PDF" defaultValue={false} />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12}>
                      {formData.prizesArePdf ? (
                        <FileInput
                          source="prizes"
                          accept={'application/pdf'}
                          maxSize={3000000}
                          multiple
                          placeholder={<p>Déposez les PDFs à uploader, ou cliquez pour en sélectionner.</p>}>
                          <ImageField source="src" title="title" />
                        </FileInput>
                      ) : (
                        <ImageInput source="prizes" accept={'image/*'} maxSize={3000000} multiple>
                          <ImageField source="src" title="title" />
                        </ImageInput>
                      )}
                    </Grid>
                  </>
                )
              }
            </FormDataConsumer>
          </Grid>
        </FormTab>
        <FormTab label={translate('resources.challenge.tabs.notification')}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Labeled label="resources.challenge.helpers.notification">
                <BooleanInput fullWidth source="defaultNotify" defaultValue={true} />
              </Labeled>
            </Grid>
            <Grid item xs={12}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.defaultNotify === false && (
                    <DateTimeInput fullWidth source="sentDate" validate={[required(), minValue(new Date())]} />
                  )
                }
              </FormDataConsumer>
            </Grid>
          </Grid>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

export default ChallengeCreate
