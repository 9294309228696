import React, { useState } from 'react'
import { Form, useLogin, useNotify, useTranslate } from 'react-admin'
import { useParams } from 'react-router-dom'
import { Box, Button, Checkbox, FormControlLabel, IconButton, TextField, Tooltip, Typography } from '@mui/material'
import { Description } from '@mui/icons-material'

import AuthLayout from '../customAdminUi/AuthLayout'

const ConfirmPartner = (props: any) => {
  const notify = useNotify()
  const login = useLogin()
  const translate = useTranslate()
  const { token, email } = useParams()
  const [loading, setLoading] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [cgu, setCgu] = useState<boolean>(false)

  const confirmPartner = async () => {
    setLoading(true)
    const body = JSON.stringify({
      token,
      email,
      password
    })
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/confirm-partner`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    })
    setLoading(false)
    if (res && res.ok) {
      notify(translate('resources.auth.form.reset'), { type: 'success' })
      login({ email, password }).then(() => {
        notify('Bienvenue dans votre tableau de bord personnalisé', { type: 'info' })
      })
    } else {
      const data = await res.json()
      notify(translate(`resources.auth.form.errors.${data.message}`), { type: 'warning' })
    }
  }

  return (
    <AuthLayout>
      <Form onSubmit={confirmPartner}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography gutterBottom>Veuillez définir votre mot de passe pour terminer.</Typography>
          <Typography gutterBottom align="center">
            Le mot de passe doit comporter 8 caractères
          </Typography>
          <Typography gutterBottom align="center">
            avec au moins 1 lettre et 1 chiffre
          </Typography>
          <TextField
            fullWidth
            autoFocus
            style={{ marginTop: 16 }}
            name="password"
            type="password"
            value={password}
            label="Mot de passe"
            error={password === ''}
            helperText={password === '' ? 'Le mot de passe est obligatoire' : ' '}
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            fullWidth
            name="confirmPassword"
            type="password"
            value={confirmPassword}
            label="Répéter le mot de passe"
            error={confirmPassword === '' || password !== confirmPassword}
            helperText={
              confirmPassword === '' ? 'Champ obligatoire' : password !== confirmPassword ? 'Les champs ne correspondent pas' : ' '
            }
            required
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 16 }}>
            <FormControlLabel
              control={<Checkbox color="primary" checked={cgu} onChange={() => setCgu(!cgu)} name="cgu" />}
              label="J'ai lu et j'accepte les CGU"
            />
            <Tooltip title={translate('resources.partner.actions.openterms')}>
              <IconButton
                aria-label="terms"
                color="primary"
                onClick={() => window.open('https://www.queeny.fr/conditions-generales-utilisation/')}>
                <Description />
              </IconButton>
            </Tooltip>
          </div>
          <Button variant="contained" color="primary" type="submit" disabled={loading || password === '' || confirmPassword === '' || !cgu}>
            {translate('resources.partner.actions.definePassword')}
          </Button>
        </Box>
      </Form>
    </AuthLayout>
  )
}

export default ConfirmPartner
