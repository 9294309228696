import React from 'react'
import { Datagrid, Link, List, ListProps, ShowButton, TextField, TopToolbar, useCreatePath, useGetOne, useRecordContext } from 'react-admin'
import { ShortDateField } from '../components/ShortDateTimeField'
import { Order } from '../types'

const OrderChallengeLinkField = (props: any) => {
  const order = useRecordContext<Order>(props)
  const createPath = useCreatePath()
  const { data } = useGetOne('challenge', { id: order.challenge.id })
  if (data) {
    return <Link to={createPath({ resource: 'challenge', type: 'show', id: order.challenge.id })}>{data.name}</Link>
  } else {
    return null
  }
}

const OrderPartnerLinkField = (props: any) => {
  const order = useRecordContext<Order>(props)
  const createPath = useCreatePath()
  const { data } = useGetOne('account', { id: order.account.id })
  if (data) {
    return <Link to={createPath({ resource: 'account', type: 'show', id: order.account.id })}>{`${data.pseudo} (${data.email})`}</Link>
  } else {
    return null
  }
}

const OrderListActions = (props: any) => <TopToolbar />

const OrderList = (props: ListProps) => (
  <List {...props} actions={<OrderListActions />}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <ShortDateField source="creationDate" />
      <OrderChallengeLinkField source="challenge" />
      <OrderPartnerLinkField source="partner" />
      <ShowButton />
    </Datagrid>
  </List>
)

export default OrderList
