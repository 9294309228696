import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Datagrid, Title, List, Pagination, ShowButton, SelectInput, TopToolbar, Button } from 'react-admin'
import { CircularProgress } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'

import { SessionEndedByField, SessionEndedSelectInput, SessionLinkAccountField, SessionScoreField } from '../components/SessionFields'
import { ShortDateTimeField } from '../components/ShortDateTimeField'
import Api from '../core/api'
import { Account } from '../types'

const ChallengeSessions = (props: any) => {
  const [sort] = useState({ field: 'id', order: 'DESC' })
  const { id, criteria } = useParams()
  const navigate = useNavigate()

  const [users, setUsers] = useState<any[] | null>(null)

  useEffect(() => {
    Api.get(`account/challenge/${id}`).then(async (res) => {
      const u = await res.json<Account[]>()
      setUsers(u.map((user: Account) => ({ id: user.id, name: `${user.pseudo} (${user.email})` })))
    })
  }, [id])

  return (
    <Fragment>
      <Title title={`Challenge n°${id} : liste des dernières `} />
      <TopToolbar>
        <Button color="primary" startIcon={<ChevronLeft />} onClick={() => navigate(`/challenge/${id}/show`)} label="Retour au Challenge" />
        <div style={{ width: 1, flex: 1 }} />
      </TopToolbar>
      {users ? (
        <List
          {...props}
          resource="session"
          basePath={`challenge/${id}/sessions`}
          sort={sort}
          filter={{ 'challenge.id': id }}
          filters={[
            <SelectInput
              placeholder="Filtrer par utilisateur"
              source="account.id"
              label="resources.account.filters.user"
              alwaysOn
              resettable
              choices={users}
            />,
            <SessionEndedSelectInput alwaysOn source="endedBy" />
          ]}
          perPage={25}
          pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}>
          <Datagrid>
            <ShortDateTimeField source="deviceStartDate" />
            <SessionEndedByField source="endedBy" />
            <SessionLinkAccountField source="pseudo" sortable={false} />
            <SessionLinkAccountField source="email" sortable={false} />
            <SessionScoreField source={criteria} />
            <ShowButton label="" />
          </Datagrid>
        </List>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
          <CircularProgress />
        </div>
      )}
    </Fragment>
  )
}

export default ChallengeSessions
