import React from 'react'
import {
  Datagrid,
  EditButton,
  Link,
  List,
  ListProps,
  Pagination,
  ShowButton,
  TextField,
  TopToolbar,
  useCreatePath,
  useGetOne,
  useRecordContext
} from 'react-admin'
import { ShortDateField } from '../components/ShortDateTimeField'
import { useCanAccess } from '../core/hooks'
import { CrudActions, Team } from '../types'

const TeamChallengeLinkField = (props: any) => {
  const team = useRecordContext<Team>(props)
  const { data, isLoading } = useGetOne('challenge', { id: team?.challenge.id })
  const createPath = useCreatePath()
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'challenge', type: 'show', id: team?.challenge.id })}>{data.name}</Link>
  } else {
    return null
  }
}

const TeamListActions = (props: any) => <TopToolbar />

const TeamList = (props: ListProps) => {
  const { canAccess } = useCanAccess({ action: CrudActions.UpdateOne, resource: 'team' })
  return (
    <List
      {...props}
      perPage={20}
      pagination={<Pagination rowsPerPageOptions={[20, 50, 100]} />}
      sort={{ field: 'id', order: 'DESC' }}
      actions={<TeamListActions />}>
      <Datagrid>
        <TextField source="id" />
        <ShortDateField source="creationDate" />
        <TextField source="name" />
        <TeamChallengeLinkField source="challenge" />
        <ShowButton label="Voir" />
        {canAccess && <EditButton />}
      </Datagrid>
    </List>
  )
}

export default TeamList
