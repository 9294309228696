import React, { useEffect, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useParams } from 'react-router-dom'
import { Box, CircularProgress, Typography } from '@mui/material'

import AuthLayout from '../customAdminUi/AuthLayout'

const ConfirmAccount = (props: any) => {
  const notify = useNotify()
  const translate = useTranslate()
  const { token, email } = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)

  useEffect(() => {
    const confirmAccount = async () => {
      if (loading && token && email) {
        const body = JSON.stringify({ token, email })
        const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/confirm-account`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body
        })
        setLoading(false)
        if (res && res.ok) {
          notify(translate('resources.auth.form.confirmed'), { type: 'success' })
        } else {
          const data = await res.json()
          notify(translate(`resources.auth.form.errors.${data.message}`), { type: 'warning' })
          setHasError(true)
        }
      }
    }
    confirmAccount()
  }, [notify, translate, hasError, loading, email, token])

  return (
    <AuthLayout>
      <Box display="flex" alignItems="center" flexDirection="column">
        {loading ? (
          <>
            <Typography gutterBottom>Veuillez patienter pendant la validation...</Typography>
            <CircularProgress />
          </>
        ) : hasError ? (
          <>
            <Typography gutterBottom variant="h5">
              Désolé :(
            </Typography>
            <Typography gutterBottom>Une erreur est survenue pendant la confirmation de votre adresse email</Typography>
          </>
        ) : (
          <>
            <Typography gutterBottom variant="h4">
              Merci !
            </Typography>
            <Typography gutterBottom>Votre compte est maintenant validé.</Typography>
            <Typography gutterBottom>Vous pouvez fermer cette fenêtre</Typography>
          </>
        )}
      </Box>
    </AuthLayout>
  )
}

export default ConfirmAccount
