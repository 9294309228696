import React from 'react'
import {
  useTranslate,
  useRecordContext,
  Edit,
  TextInput,
  Datagrid,
  ReferenceManyField,
  Pagination,
  BooleanInput,
  required,
  email,
  ImageInput,
  FormTab,
  TabbedForm,
  ImageField,
  Button,
  FormDataConsumer
} from 'react-admin'
import { useNavigate } from 'react-router-dom'
import { Box, Card, CardContent, Grid, Paper, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import AvatarImageField from '../components/AvatarImageField'
import { SessionLinkChallengeField, SessionEndedByField } from '../components/SessionFields'
import { ShortDateTimeField } from '../components/ShortDateTimeField'
import { Account } from '../types'
import Api from '../core/api'

const useStyles = makeStyles((theme: Theme) => ({
  formTab: {
    width: 'unset'
  },
  root: {
    display: 'flex'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    paddingBottom: 8
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

const Title = (props: any) => {
  const record = useRecordContext<Account>(props)
  if (record) {
    return (
      <span>
        Utilisateur n°{record.id} : {record.pseudo}
      </span>
    )
  } else {
    return <span>Chargement de l'utilisateur...</span>
  }
}

const AccountEdit = (props: any) => {
  const translate = useTranslate()
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <Edit {...props} title={<Title />}>
      <TabbedForm>
        <FormTab label={translate('resources.account.tabs.intro')}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <AvatarImageField source="image" maxHeight={100} useFallback />
                </Grid>
                <Grid item xs={10}>
                  <ImageInput source="image" accept="image/*" maxSize={3000000} label="resources.account.fields.image.filename">
                    <ImageField source="src" title="title" />
                  </ImageInput>
                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      if (formData.image && formData.image.title) {
                        return (
                          <Button
                            label="Remplacer l'avatar"
                            onClick={async () => {
                              const body = new FormData()
                              body.append('id', formData.id)
                              body.append('avatar', formData.image.rawFile)
                              const res = await Api.postFormData('account/photo2', body)
                              if (res.status === 201) {
                                navigate('show')
                              }
                            }}
                          />
                        )
                      } else {
                        return <Button label="Remplacer l'avatar" disabled />
                      }
                    }}
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput source="email" fullWidth validate={[required(), email()]} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput source="pseudo" fullWidth validate={required()} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput source="firstName" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput source="lastName" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput source="postalCode" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput source="city" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput source="phone" fullWidth />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Box m={2}>
            <Paper>
              <Card className={classes.root}>
                <CardContent style={{ minWidth: 600 }}>
                  {[
                    'notifiable',
                    'notifChallengeNew',
                    'notifChallengeEndSoon',
                    'notifSoloNotLeading',
                    'notifTeamNotLeading',
                    'notifTeamWon',
                    'notifFromQueeny',
                    'notifFromPartners'
                  ].map((key) => (
                    <div key={key} className={classes.flexRow}>
                      <Typography style={{ flexGrow: 1 }}>{translate(`resources.account.fields.${key}`)}</Typography>
                      <BooleanInput source={key} fullWidth label="" />
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Paper>
          </Box>
        </FormTab>
        <FormTab label={translate('resources.account.tabs.sessions')}>
          <ReferenceManyField
            reference="session"
            target="account.id"
            label="resources.account.helpers.sessions"
            pagination={<Pagination />}>
            <Datagrid>
              <ShortDateTimeField source="deviceStartDate" />
              <ShortDateTimeField source="deviceEndDate" />
              <SessionEndedByField source="endedBy" />
              <SessionLinkChallengeField source="challenge" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default AccountEdit
