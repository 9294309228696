import React from 'react'
import { useRecordContext } from 'react-admin'
import { Account } from '../types'

const AvatarImageField = (props: any) => {
  const record = useRecordContext<Account>(props)
  if (record.image) {
    return <img src={record.image.filename} alt={record.image.filename} height={props.maxHeight} />
  } else if (props.useFallback) {
    return <img src={'avatar.png'} alt={'avatar'} height={props.maxHeight} />
  } else {
    return null
  }
}

export default AvatarImageField
