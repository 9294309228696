import React, { useEffect, useState } from 'react'
import { LinearProgress, Link, minValue, NumberInput, useCreatePath, useGetOne, useRecordContext, useTranslate } from 'react-admin'
import { Box, Chip, InputAdornment, Typography } from '@mui/material'
import { isBefore } from 'date-fns'

import { Challenge, ChallengeStatus } from '../types'
import Api from '../core/api'

const __DEV__ = process.env.NODE_ENV === 'development'

const dv = {
  objective: __DEV__ ? 36000 : 0
}

const ChallengeCriteriaInput = ({ label, suffix, ...rest }: { label: string; suffix: string }) => {
  let ratio: number
  if (suffix === 'Heures') {
    ratio = 3600
  } else {
    ratio = 1000
  }
  return (
    <NumberInput
      fullWidth
      source="objective"
      min="0"
      defaultValue={dv.objective}
      format={(v: number) => v / ratio}
      parse={(v: string) => parseFloat(v) * ratio}
      label={label}
      validate={minValue(1, 'Un objectif supérieur à 0 est obligatoire')}
      InputProps={{ endAdornment: <InputAdornment position="end">{suffix}</InputAdornment> }}
    />
  )
}

const ChallengeGlobalProgress = (props: any) => {
  const record = useRecordContext<Challenge>(props)
  const [globalProgress, setGlobalProgress] = useState<number | null>(null)

  useEffect(() => {
    Api.get(`challenge/${record.id}/my-progress`).then(async (res: any) => {
      const data = await res.json()
      setGlobalProgress(data.percent * 100)
    })
  })

  if (globalProgress !== null) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={globalProgress} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(globalProgress)}%`}</Typography>
        </Box>
      </Box>
    )
  } else {
    return null
  }
}

const ChallengeLinkField = (props: any) => {
  const challenge = useRecordContext<Challenge>(props)
  const createPath = useCreatePath()
  const { data, isLoading } = useGetOne('challenge', { id: challenge.id })
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'challenge', type: 'show', id: data.id })}>{data.name}</Link>
  } else {
    return null
  }
}

const ChallengeImageField = (props: any) => {
  const record = useRecordContext<Challenge>(props)
  if (record && record.images && record.images.length > 0) {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <img src={record.images[0].filename} alt={record.images[0].filename} height="50" />
        <div style={{ backgroundColor: record.images[0].averageColor, marginLeft: 8, width: 20, height: 50 }} />
      </div>
    )
  } else {
    return null
  }
}

const ObjectiveField = (props: any) => {
  const record = useRecordContext<Challenge>(props)
  if (record.criteria === 'distance') {
    return <span>{record.objective / 1000} km</span>
  } else if (record.criteria === 'calories') {
    if (record.objective < 1000) {
      return <span>{record.objective} cal</span>
    } else {
      return <span>{record.objective / 1000} kcal</span>
    }
  } else {
    return <span>{record.objective / 3600} h</span>
  }
}

const TranslatedChipField = ({ source, ...rest }: { source: string }) => {
  const translate = useTranslate()
  const record = useRecordContext<Challenge>(rest)
  return (
    <Chip style={{ paddingTop: 8, paddingBottom: 4, margin: 4 }} label={translate(`resources.challenge.${source}.${record[source]}`)} />
  )
}

const ChallengeOngoingField = (props: any) => {
  const translate = useTranslate()
  const record = useRecordContext<Challenge>(props)
  if (isBefore(new Date(record.endDate), new Date())) {
    return (
      <Chip
        style={{ background: '#9E9E9E', color: 'white', paddingTop: 8, paddingBottom: 4 }}
        label={translate('resources.challenge.onGoing.ended')}
      />
    )
  } else if (isBefore(new Date(), new Date(record.startDate))) {
    return (
      <Chip
        style={{ background: '#FF9800', color: 'black', paddingTop: 8, paddingBottom: 4 }}
        label={translate('resources.challenge.onGoing.onGoing')}
      />
    )
  } else {
    return (
      <Chip
        style={{ background: '#8BC34A', color: 'white', paddingTop: 8, paddingBottom: 4 }}
        label={translate('resources.challenge.onGoing.inProgress')}
      />
    )
  }
}

const ChallengeStatusField = (props: any) => {
  const translate = useTranslate()
  const record = useRecordContext<Challenge>(props)
  switch (record.status) {
    case ChallengeStatus.ACTIVE:
      return (
        <Chip
          style={{ background: '#8BC34A', color: 'white', paddingTop: 8, paddingBottom: 4 }}
          label={translate('resources.challenge.status.active')}
        />
      )
    case ChallengeStatus.CLOSED:
      return (
        <Chip
          style={{ background: '#9E9E9E', color: 'white', paddingTop: 8, paddingBottom: 4 }}
          label={translate('resources.challenge.status.closed')}
        />
      )
    case ChallengeStatus.DELETED:
      return (
        <Chip
          style={{ background: '#FF2D55', color: 'white', paddingTop: 8, paddingBottom: 4 }}
          label={translate('resources.challenge.status.deleted')}
        />
      )
    case ChallengeStatus.DRAFT:
      return (
        <Chip
          style={{ background: '#FF9800', color: 'black', paddingTop: 8, paddingBottom: 4 }}
          label={translate('resources.challenge.status.draft')}
        />
      )
    case ChallengeStatus.TO_VALIDATE:
      return (
        <Chip
          style={{ background: '#FFEB3B', color: 'black', paddingTop: 8, paddingBottom: 4 }}
          label={translate('resources.challenge.status.toValidate')}
        />
      )
  }
}

export {
  ChallengeCriteriaInput,
  ChallengeGlobalProgress,
  ChallengeImageField,
  ChallengeLinkField,
  ObjectiveField,
  TranslatedChipField,
  ChallengeOngoingField,
  ChallengeStatusField
}
