import React, { useCallback } from 'react'
import { required, CreateProps, email, TextInput, Create, SimpleForm, useTranslate, useNotify, useCreate } from 'react-admin'
import { useNavigate } from 'react-router-dom'

const PartnerCreate = (props: CreateProps) => {
  const translate = useTranslate()
  const notify = useNotify()
  const navigate = useNavigate()
  const [create] = useCreate()

  const onSubmit = useCallback(
    async (data) => {
      try {
        await create('partner', { data }, { returnPromise: true })
        notify('Un nouveau partenaire a été créé avec succès !', { type: 'success' })
        navigate('/partner')
      } catch (error: any) {
        console.log(error)
        notify(translate(`resources.errors.${error.message}`), { type: 'warning' })
      }
    },
    [navigate, notify, translate, create]
  )

  return (
    <Create redirect="list">
      <SimpleForm onSubmit={onSubmit}>
        <TextInput fullWidth source="email" label="resources.account.fields.email" validate={[required(), email()]} type="email" />
        <TextInput fullWidth source="pseudo" label="resources.account.fields.pseudo" validate={[required()]} />
        <TextInput fullWidth source="lastName" label="resources.account.fields.lastName" />
        <TextInput fullWidth source="firstName" label="resources.account.fields.firstName" />
      </SimpleForm>
    </Create>
  )
}

export default PartnerCreate
