import React from 'react'
import { BooleanField, DateField, NumberField, ReferenceField, Show, ShowProps, SimpleShowLayout, TextField } from 'react-admin'

const OrderShow = (props: ShowProps) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <NumberField source="id" />
        <NumberField source="total" />
        <TextField source="orderNumber" />
        <DateField source="creationDate" />
        <ReferenceField source="account.id" reference="account">
          <TextField source="pseudo" />
          &nbsp;
          <TextField source="firstName" />
          &nbsp;
          <TextField source="lastName" />
          &nbsp;
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField source="challenge.id" reference="challenge">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="statsDevice" />
        <BooleanField source="statsPhone" />
        <BooleanField source="statsAddress" />
      </SimpleShowLayout>
    </Show>
  )
}

export default OrderShow
