import React from 'react'
import {
  Datagrid,
  Link,
  NumberField,
  Pagination,
  ReferenceManyField,
  Show,
  ShowButton,
  ShowProps,
  SimpleShowLayout,
  TextField,
  useCreatePath,
  useGetOne,
  useRecordContext,
  useTranslate
} from 'react-admin'
import { Grid, FormControl, FormHelperText } from '@mui/material'

import { OsField } from './list'
import { ShortDateField, ShortDateTimeField } from '../components/ShortDateTimeField'
import { Device } from '../types'
import { SessionRowStyle, SessionEndedByField, SessionLinkChallengeField, SessionScoreField } from '../components/SessionFields'

const DeviceLinkAccountField = (props: any) => {
  const device = useRecordContext<Device>(props)
  const { data, isLoading } = useGetOne('account', { id: device.account.id })
  const createPath = useCreatePath()
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'account', type: 'show', id: data.id })}>{data[props.source]}</Link>
  } else {
    return null
  }
}

const DeviceShow = (props: ShowProps) => {
  const translate = useTranslate()
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <NumberField source="id" />
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.device.fields.brand')}</FormHelperText>
          <TextField source="brand" />
        </FormControl>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.device.fields.model')}</FormHelperText>
          <TextField source="model" />
        </FormControl>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.device.fields.os')}</FormHelperText>
          <OsField source="os" />
        </FormControl>
        <Grid container>
          <Grid item xs={6} sm={2}>
            <FormControl style={{ display: 'flex', marginBottom: 4 }}>
              <FormHelperText>{translate('resources.device.fields.creationDate')}</FormHelperText>
              <ShortDateField source="creationDate" />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={2}>
            <FormControl style={{ display: 'flex', marginBottom: 4 }}>
              <FormHelperText>{translate('resources.device.fields.updateDate')}</FormHelperText>
              <ShortDateField source="updateDate" />
            </FormControl>
          </Grid>
        </Grid>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.device.fields.account')}</FormHelperText>
          <DeviceLinkAccountField source="pseudo" />
        </FormControl>
        <ReferenceManyField reference="session" target="device.id" label="resources.device.helpers.sessions" pagination={<Pagination />}>
          <Datagrid bulkActionButtons={false} rowStyle={SessionRowStyle}>
            <ShortDateTimeField source="deviceStartDate" />
            <ShortDateTimeField source="deviceEndDate" />
            <SessionEndedByField source="endedBy" />
            <SessionLinkChallengeField source="challenge" />
            <SessionScoreField source="score" />
            <ShowButton resource="session" label="Voir" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  )
}

export default DeviceShow
