import React from 'react'
import {
  AutocompleteInput,
  BulkDeleteButton,
  DatagridConfigurable,
  DateInput,
  DeleteButton,
  EditButton,
  FilterButton,
  List,
  ListProps,
  Pagination,
  ReferenceInput,
  SelectColumnsButton,
  ShowButton,
  TopToolbar
} from 'react-admin'

import { ShortDateTimeField } from '../components/ShortDateTimeField'
import {
  SessionEndedByField,
  SessionEndedSelectInput,
  SessionLinkAccountField,
  SessionLinkChallengeField,
  SessionLinkDeviceField,
  SessionRowStyle,
  SessionScoreField
} from '../components/SessionFields'
import { useCanAccess } from '../core/hooks'
import { CrudActions } from '../types'

const sessionFilters = [
  <SessionEndedSelectInput alwaysOn source="endedBy" />,
  <DateInput alwaysOn source="deviceStartDate" />,
  <ReferenceInput
    allowEmpty={false}
    label="Challenges"
    source="challenge.id"
    reference="challenge_session"
    filterToQuery={(searchText: string) => ({ s: [searchText] })}>
    <AutocompleteInput
      label="Challenge"
      style={{ minWidth: 260 }}
      optionText={(r) => {
        if (r) {
          return `${r.id} - ${r.name}`
        } else {
          return ''
        }
      }}
    />
  </ReferenceInput>,
  <ReferenceInput
    allowEmpty={false}
    label="Utilisateurs"
    source="account.id"
    reference="account"
    enableGetChoices={() => true}
    filterToQuery={(searchText: string) => ({ s: [searchText] })}>
    <AutocompleteInput
      label="Utilisateur"
      optionText={(r) => {
        if (r) {
          return `${r.id} - ${r.pseudo}`
        } else {
          return ''
        }
      }}
    />
  </ReferenceInput>
]

const SessionListBulkActions = () => <BulkDeleteButton mutationMode="pessimistic" />

const SessionListActions = (props: any) => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
  </TopToolbar>
)

const SessionList = (props: ListProps) => {
  const { canAccess: canAccessEdit } = useCanAccess({ action: CrudActions.UpdateOne, resource: 'session' })
  const { canAccess: canAccessDelete } = useCanAccess({ action: CrudActions.DeleteOne, resource: 'session' })
  const { canAccess: canAccessAccount } = useCanAccess({ action: CrudActions.ReadAll, resource: 'account' })
  return (
    <List
      {...props}
      filters={sessionFilters}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      sort={{ field: 'deviceStartDate', order: 'DESC' }}
      actions={<SessionListActions />}>
      <DatagridConfigurable bulkActionButtons={<SessionListBulkActions />} rowStyle={SessionRowStyle}>
        <ShortDateTimeField source="deviceStartDate" />
        <SessionEndedByField source="endedBy" />
        <SessionLinkChallengeField source="challenge" />
        {canAccessAccount && <SessionLinkAccountField source="pseudo" />}
        {canAccessAccount && <SessionLinkDeviceField source="device" />}
        <SessionScoreField source="score" />
        <ShowButton label="" />
        {canAccessEdit && <EditButton label="" />}
        {canAccessDelete && <DeleteButton label="Supprimer" mutationMode="pessimistic" />}
      </DatagridConfigurable>
    </List>
  )
}

export default SessionList
