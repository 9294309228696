import React, { useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useParams } from 'react-router-dom'
import { Avatar, Button, TextField, Typography } from '@mui/material'
import { Lock } from '@mui/icons-material'

import AuthLayout from '../customAdminUi/AuthLayout'

const ResetPassword = (props: any) => {
  const notify = useNotify()
  const translate = useTranslate()
  const { token } = useParams()

  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const submit = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    const body = JSON.stringify({
      token,
      password
    })
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body
    })
    setLoading(false)
    if (res && res.ok) {
      notify(translate('resources.auth.form.reset'), { type: 'success' })
      setPassword('')
      setConfirmPassword('')
    } else {
      const data = await res.json()
      notify(translate(`resources.auth.form.errors.${data.message}`), { type: 'warning' })
    }
  }

  return (
    <AuthLayout>
      <form onSubmit={submit} style={{ display: 'flex', flexDirection: 'column', minWidth: 300 }}>
        <div style={{ flexDirection: 'row', display: 'flex', maxWidth: 340 }}>
          <Avatar style={{ alignSelf: 'center', marginBottom: 16, marginRight: 16 }}>
            <Lock />
          </Avatar>
          <Typography gutterBottom>Si vous avez perdu votre mot de passe, vous pouvez le réinitialiser ci-dessous</Typography>
        </div>
        <TextField
          style={{ marginBottom: 16 }}
          autoFocus
          name="password"
          type="password"
          value={password}
          label="Mot de passe"
          error={password === ''}
          helperText={password === '' ? 'Le mot de passe est obligatoire' : ' '}
          required
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          style={{ marginBottom: 16 }}
          autoFocus
          name="confirmPassword"
          type="password"
          value={confirmPassword}
          label="Répéter le mot de passe"
          error={confirmPassword === '' || password !== confirmPassword}
          helperText={confirmPassword === '' ? 'Champ obligatoire' : password !== confirmPassword ? 'Les champs ne correspondent pas' : ' '}
          required
          onChange={(e) => setConfirmPassword(e.target.value)}
        />

        <Button variant="contained" color="primary" type="submit" disabled={loading || password === '' || confirmPassword === ''}>
          Réinitialiser
        </Button>
      </form>
    </AuthLayout>
  )
}

export default ResetPassword
