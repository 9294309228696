import React from 'react'
import {
  DeleteButton,
  Edit,
  Link,
  NumberField,
  NumberInput,
  required,
  SelectInput,
  ShowButton,
  ShowProps,
  SimpleForm,
  TopToolbar,
  useCreatePath,
  useGetOne,
  useRecordContext,
  useTranslate
} from 'react-admin'
import { FormControl, FormHelperText } from '@mui/material'

import { ShortDateTimeField } from '../components/ShortDateTimeField'
import { RpmGraph, SessionLinkAccountField, SessionLinkChallengeField } from '../components/SessionFields'
import { Session } from '../types'

const DeviceLinkAccountField = (props: any) => {
  const session = useRecordContext<Session>(props)
  const createPath = useCreatePath()
  const { data, isLoading } = useGetOne('device', { id: session.account.device?.id })
  if (data && !isLoading) {
    return <Link to={createPath({ resource: 'device', type: 'show', id: data.id })}>{data.brand + ' ' + data.model}</Link>
  } else {
    return null
  }
}

const EndedByInput = (props: any) => {
  const record = useRecordContext<Session>(props)
  return (
    record && (
      <SelectInput
        defaultValue={record.endedBy}
        validate={required()}
        source="endedBy"
        label="resources.session.fields.endedBy"
        choices={[
          { id: 'bluetooth_lost', name: 'resources.session.endedBy.bluetoothLost' },
          { id: 'force', name: 'resources.session.endedBy.force' },
          { id: 'manual', name: 'resources.session.endedBy.manual' },
          { id: 'server_stop_ghost_session', name: 'resources.session.endedBy.serverGhost' },
          { id: 'server_stop_session_too_old', name: 'resources.session.endedBy.serverTooOld' },
          { id: 'api_stop', name: 'resources.session.endedBy.stop' },
          { id: 'suspicious', name: 'resources.session.endedBy.suspicious' },
          { id: 'timeout', name: 'resources.session.endedBy.timeout' },
          { id: 'time_up', name: 'resources.session.endedBy.timeup' },
          { id: 'unknown', name: 'resources.session.endedBy.unknown' },
          { id: 'websocketDisconnected', name: 'resources.session.endedBy.websocketDisconnected' },
          { id: 'api_send_data_win', name: 'resources.session.endedBy.win' }
        ]}
      />
    )
  )
}

const SessionEditActions = (props: any) => (
  <TopToolbar>
    <ShowButton record={props.data} />
    <DeleteButton record={props.data} mutationMode="pessimistic" />
  </TopToolbar>
)

const SessionEdit = (props: ShowProps) => {
  const translate = useTranslate()
  return (
    <Edit {...props} actions={<SessionEditActions />}>
      <SimpleForm>
        <NumberField source="id" />
        <FormControl style={{ marginBottom: 8, marginRight: 16 }}>
          <FormHelperText>{translate('resources.session.fields.deviceStartDate')}</FormHelperText>
          <ShortDateTimeField source="deviceStartDate" />
        </FormControl>
        <FormControl style={{ marginBottom: 8, marginRight: 16 }}>
          <FormHelperText>{translate('resources.session.fields.deviceEndDate')}</FormHelperText>
          <ShortDateTimeField source="deviceEndDate" />
        </FormControl>
        <FormControl>
          <FormHelperText>{translate('resources.session.fields.updateDate')}</FormHelperText>
          <ShortDateTimeField source="updateDate" />
        </FormControl>
        <EndedByInput />
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.session.fields.challenge.name')}</FormHelperText>
          <SessionLinkChallengeField source="challenge" />
        </FormControl>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.account.fields.pseudo')}</FormHelperText>
          <SessionLinkAccountField source="pseudo" />
        </FormControl>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.account.fields.email')}</FormHelperText>
          <SessionLinkAccountField source="email" />
        </FormControl>
        <FormControl style={{ marginBottom: 4 }}>
          <FormHelperText>{translate('resources.session.fields.device')}</FormHelperText>
          <DeviceLinkAccountField source="device" />
        </FormControl>
        <NumberField source="duration" />
        <NumberInput source="distance" />
        <NumberInput source="calories" />
        <RpmGraph source="rpms" />
      </SimpleForm>
    </Edit>
  )
}

export default SessionEdit
