import React from 'react'
import { Datagrid, List, ListProps, ShowButton, TextField } from 'react-admin'
import { ShortDateField } from '../components/ShortDateTimeField'

const PartnerList = (props: ListProps) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid bulkActionButtons={false}>
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="pseudo" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <ShortDateField source="creationDate" />
      <ShowButton label="Voir" resource="account" />
    </Datagrid>
  </List>
)

export default PartnerList
