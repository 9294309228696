import React, { createContext, FC } from 'react'
import useProfile, { UseProfileType, initialProfileContext } from './useProfile'

export const ProfileContext = createContext<UseProfileType>(initialProfileContext)

const ProfileProvider: FC = ({ children }) => {
  const profileContext = useProfile()
  return <ProfileContext.Provider value={profileContext}>{children}</ProfileContext.Provider>
}

export default ProfileProvider
