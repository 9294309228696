import React from 'react'
import {
  CreateButton,
  DatagridConfigurable,
  FilterButton,
  List,
  ListProps,
  NumberField,
  Pagination,
  SearchInput,
  SelectColumnsButton,
  SelectInput,
  ShowButton,
  TextField,
  TopToolbar,
  useRecordContext,
  useTranslate
} from 'react-admin'
import { Chip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'

import {
  ChallengeImageField,
  ChallengeOngoingField,
  ChallengeStatusField,
  ObjectiveField,
  TranslatedChipField
} from '../components/ChallengeFields'
import { ShortDateField } from '../components/ShortDateTimeField'
import { Challenge, ChallengeStatus, CrudActions } from '../types'
import { useCanAccess } from '../core/hooks'

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    //marginBottom: theme.spacing(1)
  }
}))

const QuickFilter = ({ label, source, defaultValue }: { label: string; source: string; defaultValue: any }) => {
  const translate = useTranslate()
  const classes = useQuickFilterStyles()
  return <Chip className={classes.chip} label={translate(label)} />
}

const ChallengeFilters = () => {
  const translate = useTranslate()
  return [
    <SearchInput fullWidth source="s" alwaysOn />,
    <SelectInput
      alwaysOn
      source="scope"
      choices={[
        { id: 'private', name: translate('resources.challenge.scope.private') },
        { id: 'public', name: translate('resources.challenge.scope.public') }
      ]}
    />,
    <SelectInput
      alwaysOn
      source="type"
      choices={[
        { id: 'solo', name: translate('resources.challenge.type.solo') },
        { id: 'team', name: translate('resources.challenge.type.team') }
      ]}
    />,
    <SelectInput
      alwaysOn
      source="onGoing"
      choices={[
        { id: 'ended', name: translate('resources.challenge.onGoing.ended') },
        { id: 'ongoing', name: translate('resources.challenge.onGoing.inProgress') }
      ]}
    />,
    <SelectInput
      source="status"
      choices={[
        { id: ChallengeStatus.ACTIVE, name: translate('resources.challenge.status.active') },
        { id: ChallengeStatus.DRAFT, name: translate('resources.challenge.status.draft') },
        { id: ChallengeStatus.TO_VALIDATE, name: translate('resources.challenge.status.toValidate') },
        { id: ChallengeStatus.CLOSED, name: translate('resources.challenge.status.closed') },
        { id: ChallengeStatus.DELETED, name: translate('resources.challenge.status.deleted') }
      ]}
    />,
    <QuickFilter source="global" label="Global" defaultValue={true} />
  ]
}

const ChallengeListActions = (props: any) => {
  const { canAccess } = useCanAccess({ action: CrudActions.CreateOne, resource: 'challenge' })
  return (
    <TopToolbar>
      <SelectColumnsButton />
      <FilterButton />
      {canAccess && <CreateButton />}
    </TopToolbar>
  )
}

const ChallengeDescriptionPanel = () => {
  const record = useRecordContext<Challenge>()
  return (
    <div>
      <Typography variant="caption">Description</Typography>
      <Typography variant="body1">{record.description}</Typography>
    </div>
  )
}

const ChallengeList = (props: ListProps) => {
  const translate = useTranslate()
  return (
    <List
      {...props}
      title={translate('resources.challenge.title')}
      filters={ChallengeFilters()}
      perPage={25}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100]} />}
      actions={<ChallengeListActions />}
      sort={{ field: 'priority', order: 'DESC' }}>
      <DatagridConfigurable bulkActionButtons={false} expand={<ChallengeDescriptionPanel />}>
        <NumberField source="priority" />
        <TextField source="id" />
        <ChallengeImageField source="image" sortable={false} />
        <TextField source="name" sortable={false} />
        <ObjectiveField source="objective" />
        <TranslatedChipField source="scope" />
        <TranslatedChipField source="type" />
        <ShortDateField source="startDate" />
        <ShortDateField source="endDate" />
        <ChallengeOngoingField source="onGoing" sortable={false} />
        <ChallengeStatusField source="status" />
        <ShowButton label="Voir" />
      </DatagridConfigurable>
    </List>
  )
}

export default ChallengeList
