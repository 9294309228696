import React from 'react'
import { DateField as RADateField, useRecordContext } from 'react-admin'
import { Typography } from '@mui/material'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import { Challenge } from '../types'

export const ShortDateTimeField = (props: any) => (
  <RADateField
    source={props.source}
    showTime
    options={{ year: '2-digit', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric' }}
  />
)

export const ShortDateField = (props: any) => (
  <RADateField source={props.source} options={{ year: '2-digit', month: '2-digit', day: '2-digit' }} />
)

export const DateField = (props: any) => {
  const record = useRecordContext<Challenge>(props)
  if (record[props.source]) {
    return (
      <Typography variant="body2">
        {format(utcToZonedTime(new Date(record[props.source]), 'Europe/Paris'), 'dd/MM/yyyy HH:mm:ss')}
      </Typography>
    )
  } else {
    return <Typography variant="body2">(non renseigné)</Typography>
  }
}
